<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3"
        text
        v-on="on"
        small
      >
        Delayed Payments
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Delayed Payments</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" @click.stop="dialog = false" fab small>
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pt-3">
        <v-window v-model="window">
          <v-window-item :value="0">
            <div class="subtitle-1">To collect payment information without a payment and charge the registrants at a later time, click the button below.</div>
            <div class="text-center">
              <v-btn color="color3 color3Text--text" @click.stop="window++">
                Turn on Delayed payments
              </v-btn>
            </div>
          </v-window-item>
          <v-window-item :value="1">
            <v-alert type="warning" :value="true" prominent text>
              <v-row align="center">
                <v-col class="grow black--text">
                  By turning on delayed payment, the platform will capture payment information that you can be charged at a later date when you confirm the event is happening. <b>NO payments will be collected during the registration process.</b>
                </v-col>
                <v-col class="shrink">
                  <v-btn
                    @click.stop="window++"
                  >continue</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-window-item>
          <v-window-item :value="2">
            <v-switch label="Delayed Payments" v-model="preAuth" color="color3"></v-switch>
            <v-expand-transition>
              <v-textarea
                v-if="preAuth"
                v-model="preAuthMessage"
                outlined
                label="Message to users"
                color="color3"
                placeholder="Please enter a message to your users letting them know when you will process the payments"
                :error-messages="preAuthMessage ? [] : ['A message is required']"
              ></v-textarea>
            </v-expand-transition>
          </v-window-item>
        </v-window>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-fab-transition>
          <v-btn
            color="success"
            :disabled="!saveable"
            :loading="loading"
            small fab
            @click.stop="save"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      ack: false,
      preAuth: false,
      preAuthMessage: null,
      window: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    saveable () {
      return this.window === 2 && this.dirty && this.preAuthMessage
    },
    dirty () {
      return (this.jProps.preAuth !== this.preAuth) || (this.jProps.preAuthMessage !== this.preAuthMessage)
    },
    jProps () {
      return this.tournament.jProps || {}
    }
  },
  methods: {
    save () {
      if (!this.dirty) return
      this.loading = true
      if (!this.tournament.jProps) this.tournament.jProps = {}
      this.tournament.jProps.preAuth = this.preAuth
      this.tournament.jProps.preAuthMessage = this.preAuthMessage
      this.tournament.saveJProps()
        .then(r => {
          this.dialog = false
        })
    }
  },
  watch: {
    dialog: function (v) {
      if (v) {
        if (this.tournament.jProps) {
          this.preAuth = this.tournament.jProps.preAuth
          this.preAuthMessage = this.tournament.jProps.preAuthMessage
        }
        this.window = this.preAuth ? 2 : 0
        this.loading = false
      }
    }
  }
}
</script>
